import { Route, Routes } from 'react-router-dom'
import {
  CheckInPage,
  PaymentCreatePage,
  PaymentCompletePage,
  PaymentFailurePage,
  PaymentGuidePage,
  PaymentSelfCheckoutPage,
  PaymentSorryPage,
  PaymentSuccessPage,
  RegistrationPage,
  RegistrationWithFamilyPage,
  RegistrationCheckedInPage,
  RegistrationCompletePage,
  UpdateCompletePage,
  UpdatePage,
} from './pages'
import { NoMatch } from '../common/pages'
import { TransferToFamilyPage } from './pages/TransferToFamily'
import { TransferToFamilyCompletePage } from './pages/TransferToFamilyComplete'
import { TransferToFamilyFailurePage } from './pages/TransferToFamilyFailure'
import { LandingPage } from './pages/Landing'
import { LinyTestPage } from './pages/LinyTest'

export function UserApp() {
  return (
    <Routes>
      <Route path="*" element={<NoMatch />} />
      <Route path="/liny-test" element={<LinyTestPage />} />
      <Route path="/patients/registration" element={<RegistrationPage />} />
      <Route path="/patients/registration-with-family" element={<RegistrationWithFamilyPage />} />
      <Route path="/patients/registration/complete" element={<RegistrationCompletePage />} />
      <Route path="/patients/registration/checked-in" element={<RegistrationCheckedInPage />} />
      <Route path="/patients/transfer-to-family" element={<TransferToFamilyPage />} />
      <Route
        path="/patients/transfer-to-family/complete"
        element={<TransferToFamilyCompletePage />}
      />
      <Route
        path="/patients/transfer-to-family/failure"
        element={<TransferToFamilyFailurePage />}
      />
      <Route path="/patients/update" element={<UpdatePage />} />
      <Route path="/patients/update/complete" element={<UpdateCompletePage />} />
      <Route path="/payment/create" element={<PaymentCreatePage />} />
      <Route path="/payment/complete" element={<PaymentCompletePage />} />
      <Route path="/payment/failure" element={<PaymentFailurePage />} />
      <Route path="/payment/guide" element={<PaymentGuidePage />} />
      <Route path="/payment/success" element={<PaymentSuccessPage />} />
      <Route path="/payment/self-checkout" element={<PaymentSelfCheckoutPage />} />
      <Route path="/payment/sorry" element={<PaymentSorryPage />} />
      <Route path="/check-in" element={<CheckInPage />} />
      <Route path="/landing" element={<LandingPage />} />
    </Routes>
  )
}
