import { Button, Grid } from '@mui/material'

export function LinyTestPage() {
  return (
    <>
      <Grid container alignItems="center" justifyContent="center" sx={{ mt: 4 }}>
        <Grid item xs={12} sx={{ textAlign: 'center', mx: 2 }}>
          <Button
            href="https://liff.line.me/1660782453-9GaXgYGb/landing?follow=@766xhgev&liff_id=1660782453-9GaXgYGb&lp=eKp84p"
            variant="outlined"
            fullWidth
            sx={{
              borderColor: '#666666',
              color: '#666666',
              fontSize: 16,
            }}
          >
            LINYを開く
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
